<script setup>
import { useStore } from 'vuex'
import router from '@/router'
import { EaseChatSDK, EaseChatClient } from '@/IM/initwebsdk'
import { handleSDKErrorNotifi, setMessageKey } from '@/utils/handleSomeData'
import { informType } from '@/constant'
import { usePlayRing } from '@/hooks'
import ring from '@/assets/ring.mp3'
import {
    captchaImage,
    loginCustom,
    getImToken,
    getUserInfo,
    getOtherGroupInfo
} from '@/api/custom'
/* callkit */
import EaseCallKit from '@/components/EaseCallKit'
import InviteCallMembers from '@/components/InviteCallMembers'
import { ElLoading, ElMessageBox } from 'element-plus'
import { ref, onMounted, onUnmounted,nextTick } from 'vue'
const store = useStore()
const { isOpenPlayRing, clickRing } = usePlayRing()
EaseChatSDK.logger.disableAll()

onMounted(() => {
    // 自己服务器做个心跳包，定时请求接口，避免token超时
    clearInterval()
    setInterval(() => {
        if (router.currentRoute.value.path != '/login') {
            getUserInfo()
        }
    }, 5 * 60 * 1000)

    store.dispatch('getLocalUnreadMsgIds')

    // 监听子窗口  跳转会话页
    nextTick(() => {
      console.log("%c Line:42 🍬 监听子窗口", "color:#ea7e5c");
      window.addEventListener("message", (e) => {
        if (e) {
          console.log("%c Line:42 🍬 收到子窗口消息aaa", "color:#ea7e5c", e);
          let childData = ''
          try {
            childData = JSON.parse(e.data)
          } catch (error) {
            childData = e.data
          }
          childData
          console.log("%c Line:49 🍩 childData", "color:#ffdd4d", childData);
          if (childData&&childData.type == "goChat") {
            router.push({
              path:'/chat/conversation',
              query:{
                id:childData.hxusername,
                chatType:childData.chatType
              }
            })
          } 
          if (childData&&childData.type == "getUserInfoToParentWindow") {
            store.dispatch('getCustomUserInfo')
          } 
        }
      });
    });
})
onUnmounted(() => {
    clearInterval()
})
/* connect 相关监听 */
EaseChatClient.addEventHandler('connection', {
    onConnected: () => {
        //console.log('>>>>>环信连接成功')
        // 关闭时通知父窗口
        if(window.opener&&!window.opener.closed){
          // window.opener.windowMessage
          // 关闭本窗口时进行提示
          window.addEventListener('beforeunload', function(event) {
            window.opener.postMessage('closed','https://erp.yududs.com')
            window.opener.postMessage('closed','http://yudu.yududs.com')
            window.opener.postMessage('closed','http://192.168.3.101')
            EaseChatClient.close()
          });
        }
        store.commit('CHANGE_LOGIN_STATUS', true)
        if (isOpenPlayRing.value) clickRing()
        fetchLoginUsersInitData()
        let show = window.sessionStorage.getItem('showNav')
        //console.log('%c Line:26 🍅 show', 'color:#33a5ff', show)
        // if(show) show = JSON.stringify(show)
        router.replace({
            // path: '/chat/conversation',//纯IM
            path: '/chat/erp',//商户版
            query: { showNav: show ? show : true }
        })
    },
    onDisconnected: async (e) => {
      // console.log("%c Line:66 🍆 e", "color:#7f2b82", e);
        // router.push('/login')
        window.localStorage.removeItem('EASEIM_loginUser')
        store.commit('CHANGE_LOGIN_STATUS', false)
        EaseChatClient.close()
        // 重新登录
        if (router.currentRoute.value.path != '/login') {
          try {
            let userInfos = await getUserInfo()
            if(userInfos){
              // 重新登录环信
              let res2 = await getImToken()
              window.localStorage.setItem(`customUserInfo`, JSON.stringify(userInfos.data))
              // 用token登录IM
              try {
                let { accessToken } = await EaseChatClient.open({
                  user: userInfos.data.hxusername.toLowerCase(),
                  accessToken: res2.msg
                });
                window.localStorage.setItem(`EASEIM_loginUser`, JSON.stringify({ user: userInfos.data.hxusername, accessToken: accessToken }))
              } catch (error) {
                router.push('/login')
              }
            }else{
              router.push('/login')
            }
          } catch (error) {
            router.push('/login')
          }
        }
        // //取本地的环信 usertoken hxUserToken
        // let usertoken = window.localStorage.getItem('hxUserToken')
        // let userInfos = window.localStorage.getItem('customUserInfo')
        // if (usertoken) usertoken = JSON.parse(usertoken)
        // if (userInfos) userInfos = JSON.parse(userInfos)
        // if (usertoken) {
        //     // 登录
        //     EaseChatClient.open({
        //         user: userInfos.hxusername.toLowerCase(),
        //         accessToken: usertoken
        //     })
        // } else {
        //     router.push('/login')
        // }
    },
    onOnline: () => {
        store.commit('CHANGE_NETWORK_STATUS', true)
    }, // 本机网络连接成功。
    onOffline: () => {
        store.commit('CHANGE_NETWORK_STATUS', false)
    }, // 本机网络掉线。
    onError: (error) => {
        // console.log('on error', error)
        if(error.type==206){
          // 设备超限 跳转登录页 
          router.push('/login')
          handleSDKErrorNotifi(error.type, '登录设备过多')
        }
        // handleSDKErrorNotifi(error.type, error.message)
    }
})
//路由跳转-对应好友会话
const toChatMessage = (id, chatType, avatarurl) => {
    // //console.log('>>>>>>>id', id)
    router.push({
        path: '/chat/conversation/message',
        query: {
            id,
            chatType,
            avatarurl
        }
    })
}
//fetch 登陆用户的初始数据
const fetchLoginUsersInitData = () => {
    getMyUserInfos()
    fetchFriendList()
    fetchTheLoginUserBlickList()
    fetchGroupList()
    //初始化vuex中的会话列表相关数据
    store.commit('INIT_CONVERSATION_STATE')
    const userId = EaseChatClient.user
    store.dispatch('getSessionList', userId)
}
//获取登陆用户属性
const getMyUserInfos = () => {
    const userId = EaseChatClient.user
    store.dispatch('getMyUserInfo', userId)
}
//获取好友列表
const fetchFriendList = () => {
    // const { value = {} } = useLocalStorage('friendList')
    // if (Object.values(JSON.parse(value)).length > 0) return
    store.dispatch('fetchFriendList')
}
//获取黑名单列表
const fetchTheLoginUserBlickList = () => store.dispatch('fetchBlackList')
//获取加入的群组列表
const fetchGroupList = () => {
    //如果本地存储里不存在群组列表则调用获取群组列表
    // const { value = {} } = useLocalStorage('groupList')
    // if (Object.values(JSON.parse(value)).length > 0) return
    const pageParams = {
        pageNum: 1,
        pageSize: 20
    }
    store.dispatch('fetchGroupList', pageParams)
}
//在线状态订阅相关
const presenceStatus = (type, user) => {
    type === 'sub' && store.dispatch('subFriendsPresence', [user])
    type === 'unsub' && store.dispatch('unsubFriendsPresence', [user])
}

/* presence 相关监听 */
EaseChatClient.addEventHandler('presenceStatusChange', {
    onPresenceStatusChange: (status) => {
        //console.log('>>>>>presenceStatusChange', status)
        getUserPresence(...status)
    }
})
//处理登陆用户状态的变更
const getUserPresence = (status) => {
    store.dispatch('handlePresenceChanges', status)
}
/* message 相关监听 */
EaseChatClient.addEventHandler('messageListen', {
    onTextMessage: function (message) {
        //console.log('>>>>>>>App mesage', message)
        //console.log('setMessageKey', setMessageKey(message))
        pushNewMessage(message)
    }, // 收到文本消息。
    onEmojiMessage: function (message) {
        pushNewMessage(message)
    }, // 收到表情消息。
    onImageMessage: function (message) {
        //console.log('>>>>>>>App Image mesage', message)
        pushNewMessage(message)
    }, // 收到图片消息。
    onCmdMessage: function (message) { // 收到命令消息。
      console.log("%c Line:207 🍯 收到命令消息。message", "color:#33a5ff", message);
      let action = JSON.parse(message.action)
      if(action.businessFunction == 'f_sxxtxx'){//系统通知更新
        if(action.from==store.state.Message.currentChatId){
          setTimeout(()=>{
            store.dispatch('reloadPage',{
              conversationKey: store.state.Message.currentChatId,
              chatType:store.state.Message.currentChatType,
              to:action?.to
            })
          },300)
        }
        return store.dispatch('getSysNotice')
      }
      let chatType = 'singleChat'
      // if (action.businessFunction == 'f_yqjsbj') chatType = 'singleChat'  //邀请接受报价
      // if (action.businessFunction == 'f_backlink') chatType = 'singleChat'  //回收账号填报链接
      // if (action.businessFunction == 'f_yqmjzf') chatType = 'groupChat' //邀请买家支付
      // if (action.businessFunction == 'f_fqht') chatType = 'groupChat' //发起合同
      // if (action.businessFunction == 'f_hswcdd') chatType = 'groupChat' //完成回收订单
      // if (action.businessFunction == 'f_hsqxdd') chatType = 'groupChat' //取消回收订单
      // if (action.businessFunction == 'f_hsbpshtg') chatType = 'groupChat' //回收包赔审核通过
      

      // // 销售订单相关
      // /**f_xsgj
      //   f_xsgz
      //   f_xsqxdd
      //   f_xswcdd */
      // if (action.businessFunction == 'f_xsgj') chatType = 'singleChat'  //销售改价
      // if (action.businessFunction == 'f_backlink') chatType = 'singleChat'  //回收账号填报链接

      // {
      //   //邀请签署合同消息已发送  更新右侧回收订单信息
      //   if(router.currentRoute.value.path=='/chat/conversation/message'&&action?.to.indexOf(router.currentRoute.value.query.id)>-1 ){
      //     dispatch('getGoodsInfo',params.conversationKey);return
      //   }
      // }
      setTimeout(()=>{
        store.dispatch('reloadPage',{
          conversationKey: store.state.Message.currentChatId,
          chatType:store.state.Message.currentChatType,
          to:action?.to
        })
      },300)
      //
      // let action = JSON.parse(message.action)
      // if (action.businessFunction == 'f_yqjsbj') {
      //   // 邀请接受报价的消息
      //   // // 更改相关消息的状态
      //   let messageIdArr = action.messageId.split(',')
      //   // const busMsgStateMap store.state.Message.businessMsgState
      //   if (messageIdArr && messageIdArr.length) {
      //     messageIdArr.forEach(msgid => {
      //       msgid = '' + msgid
      //       store.commit('UPDATE_BBUSINESS_MSG_STATE',{
      //         messageId:msgid,
      //         businessState:action
      //           .businessState
      //       })
      //     })
      //   }
      //   // 更新后台自动发送的自己的消息
      //   store.dispatch('getHistoryMessage',{
      //       cursor: -1,
      //       id:router.currentRoute.value.query.id,//对方环信id 当前聊天窗口的id
      //       chatType:'singleChat',
      //       flag:'createMyMsg'
      //     })
      //   // 更新右侧商品信息 getGoodsInfoBySingleChat
      //   // 在当前聊天页面 
      //   if(router.currentRoute.value.path=='/chat/conversation/message'&&action.to.indexOf(router.currentRoute.value.query.id)>-1 ){
      //     store.dispatch('getGoodsInfoBySingleChat',router.currentRoute.value.query.id)
      //   }
      // }
    }, // 收到命令消息。
    onAudioMessage: function (message) {
        pushNewMessage(message)
    }, // 收到音频消息。
    onLocationMessage: function (message) {
        pushNewMessage(message)
    }, // 收到位置消息。
    onFileMessage: function (message) {
        pushNewMessage(message)
    }, // 收到文件消息。
    onCustomMessage: function (message) {
        // console.log('>>>>>自定义消息', message)
        if(message.customEvent=="b_backorder"&&message.from==EaseChatClient.user){
          return
        }
        pushNewMessage(message)
    }, // 收到自定义消息。
    onVideoMessage: function (message) {
        pushNewMessage(message)
    }, // 收到视频消息。
    onRecallMessage: function (message) {
        otherRecallMessage(message)
    }, // 收到消息撤回回执。
    
  onReadMessage: function (message) {
      if(message.from!=EaseChatClient.user){
        store.commit('CHANGE_MESSAGE_BODAY', { type: 'read', key:message.from,mid:message.mid })
      }else{
      }
    },
    onChannelMessage: (message) => {
    if(message.chatType=="singleChat"){
      // 将这个会话下所有的消息都变为已读
      store.commit('CHANGE_MESSAGE_BODAY', { type: 'converReaded', key:message.from })
    }
    },
    // onDeliveredMessage: function (message) {
    //   console.log("%c Line:200 🍻 消息已送达回执message", "color:#fca650", message);

    // }, 
})
//接收的消息往store中push
const pushNewMessage = (message) => {
  // 收到消息的时候如果在私聊的会话页面，直接发送已读回执
  let route = router.currentRoute.value
  if(route.path=='/chat/conversation'&&store.state.Message.currentChatId==message.from){
    // 私聊会话，并且在当前会话页面
    let option = {
      type: "read", // 消息是否已读。
      chatType: "singleChat", // 会话类型，这里为单聊。
      to: message.from, // 消息接收方的用户 ID。
      id:message.id, // 需要发送已读回执的消息 ID。
    };
    let msg = EaseChatSDK.message.create(option);
    EaseChatClient.send(msg);
  }
    store.dispatch('createNewMessage', message)
}
//收到他人的撤回指令
const otherRecallMessage = (message) => {
    //console.log('>>>>>收到他人撤回', message)
    const { from, to, mid } = message
    //单对单的撤回to必然为登陆的用户id，群组发起撤回to必然为群组id 所以key可以这样来区分群组或者单人。
    const key = to === EaseChatClient.user ? from : to
    //console.log('>>>>>收到他人撤回', key)
    store.commit('CHANGE_MESSAGE_BODAY', { type: 'recall', key, mid })
    store.dispatch('gatherConversation', key)
}
/* 好友关系相关监听 */
const { INFORM_FROM } = informType
EaseChatClient.addEventHandler('friendListen', {
    // 收到好友邀请触发此方法。
    onContactInvited: (data) => {
        //写入INFORM
        //console.log('>>>>>>收到好友申请', data)
        submitInformData(INFORM_FROM.FRIEND, data)
    },
    // 联系人被删除时触发此方法。
    onContactDeleted: (data) => {
        //写入INFORM
        //console.log('>>>>收到好友关系解散', data)
        submitInformData(INFORM_FROM.FRIEND, data)
        //取消针对好友的在线状态订阅
        presenceStatus('unsub', data.from)
        //好友关系解除重新获取好友列表
        fetchFriendList()
    },
    // 新增联系人会触发此方法。
    onContactAdded: (data) => {
        //console.log('>>>>好友新增监听', data)
        submitInformData(INFORM_FROM.FRIEND, data)
        //新增好友重新获取好友列表
        fetchFriendList()
    },
    // 好友请求被拒绝时触发此方法。
    onContactRefuse: (data) => {
        //写入INFORM
        //console.log('>>>>>>好友申请被拒绝', data)
        data.type = 'other_person_refuse'
        submitInformData(INFORM_FROM.FRIEND, data)
    },
    // 好友请求被同意时触发此方法。
    onContactAgreed: (data) => {
        //写入INFORM
        //console.log('>>>>>对方同意了好友申请', data)
        //改掉data中的type
        data.type = 'other_person_agree'
        submitInformData(INFORM_FROM.FRIEND, data)
        //对方同意后重新获取好友列表
        fetchFriendList()
    }
})
/* 群组相关监听 */
EaseChatClient.addEventHandler('groupEvent', {
    onGroupEvent: (groupevent) => {
        submitInformData(INFORM_FROM.GROUP, groupevent)
        // 查询群组信息
        let my = EaseChatClient.user
        getOtherGroupInfo(groupevent.id,my).then(res => {
          if (res.code == 200) {
            if(my in res.data&&res.data[my].type=='owner'){
            }else{
              if(groupevent.operation=="directJoined"){
                // 进入新群聊 提示是否转到群聊
                ElMessageBox.confirm(
                  '您已进入一个新群聊，是否转到群聊?',
                  '提示',
                  {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }
                ).then(()=>{
                  // store.commit('CLEAR_UNREAD_NUM', groupevent.id)
                  // 更新会话页面
                  store.dispatch('reloadPage',{
                    conversationKey:groupevent.id,
                    flag:'clickConversation',
                    chatType:'groupChat'
                  })
                  router.push({
                      path: '/chat/conversation',
                      query: {
                          id:groupevent.id,
                          chatType:'groupChat',
                          avatarurl:''
                      }
                  })
                  
                })
              }
            }
          }
        })
    }
})

const submitInformData = (fromType, informContent) => {
    //console.log('>>>submitInformData>>>', fromType, informContent)
    store.dispatch('createNewInform', { fromType, informContent })
}

/* 重新登陆 */
//读取本地EASEIM_loginUser
let EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')

if (EASEIM_loginUser) {
    EASEIM_loginUser = JSON.parse(EASEIM_loginUser)
}
const loginUserFromStorage = EASEIM_loginUser || {}
const handleRelogin = () => {
    //console.log('重新登陆')
    EaseChatClient.open({
        user: loginUserFromStorage.user,
        accessToken: loginUserFromStorage.accessToken
    })
}
if (loginUserFromStorage?.user && loginUserFromStorage?.accessToken) {
    handleRelogin()
}
/* EaseCallKit 相关 */
const easeCallKit = ref(null)
const inviteCallComp = ref(null)
//多人会议使用-弹出邀请模态框
const showModal = ({ groupId }) => {
    //console.log('可以弹出邀请框', groupId)
    inviteCallComp.value.alertDialog(groupId)
}
//多人会议使用-传递给邀请组件发送邀请消息
const sendMulitInviteMsg = (targetIMId) => {
    //console.log('targetIMIdtargetIMIdtargetIMId', targetIMId)
    const callType = 2
    easeCallKit.value.inMultiChanelSendInviteMsg(targetIMId, callType)
}
</script>
<template>
    <router-view v-slot="{ Component }">
        <transition
            name="slide-fade"
            mode="out-in"
            :duration="{ enter: 500, leave: 300 }"
        >
            <component :is="Component" />
        </transition>
    </router-view>
    <!-- 铃声标签 -->
    <audio id="ring" :src="ring" controls hidden></audio>
    <!-- About EaseCallKit -->
    <EaseCallKit
        ref="easeCallKit"
        :EaseIMClient="EaseChatClient"
        :msgCreateFunc="EaseChatSDK.message"
        @onInviteMembers="showModal"
    />
    <InviteCallMembers
        ref="inviteCallComp"
        @sendMulitInviteMsg="sendMulitInviteMsg"
    />
</template>

<style type="scss">
@import './styles/reset/reset.css';
@import './styles/iconfont/iconfont.css';

/* .el-dialog__header{
  background: #f2f2f2;
  margin: 0 !important;
} */


</style>
