<script setup>
import { ref,onMounted } from 'vue'
import { EaseChatClient } from '@/IM/initwebsdk'
import CustomImConfig from '@/views/Login/components/CustomImConfig'
import LoginInput from './components/LoginInput'
import RegisterInput from './components/RegisterInput'
// import ResetPassword from './components/ResetPassword'
// onMounted(()=>{
//   localStorage.removeItem('customToken')
//   window.localStorage.removeItem('hxUserToken')
//   window.localStorage.removeItem('customUserInfo')
//   window.localStorage.removeItem('EASEIM_loginUser')
// })
//login图
const logo = require('@/assets/images/logo-im.png')
// 登陆注册所用
const showComponent = ref(0)
const componType = {
    0: LoginInput,
    1: RegisterInput,
    // 2: ResetPassword
}
const changeToLogin = () => {
    showComponent.value = 0
}

//服务配置
const customImConfig = ref(null)
// const showCustomImConfigModal = () => {
//   customImConfig.value.centerDialogVisible = true
// }

//SDK-Version
const IM_SDK_VERSION = EaseChatClient.version
</script>
<template>
  <el-container class="app_container">
      <div class="login_box">
        
        <div class="login_box_card ">
          <div class="login_box_inner">
            <img class="logo" :src="logo"  alt="" />
            <div class="title">虞都代售商家后台</div>
            <component :is="componType[0]" @changeToLogin="changeToLogin"></component>
          </div>
        </div>
        <div class="bg-box"></div>
      </div>
  </el-container>
</template>

<style lang="scss" scoped>
.app_container {
  width: 100vw;
  height: 100vh;
  background: #EBF2FF;
  background-size: cover;

  .login_box {
    display: flex;
    flex-direction:row-reverse;
    // justify-content: center;
    // background: #04aef0;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    .bg-box{
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/login-bg.jpg');
      background-repeat: no-repeat;
      background-size: 1336px 1080px;
      background-position: center center;
    }
    .login_box_card {
      height: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 584px;
      // margin: 0 auto;
      border-radius: 5px;
      background: #fff;
      height: 100%;
      // padding-top: 10%;
      box-sizing: border-box;
      .login_box_inner{
        height: 562px;
        text-align: center;
      }

      .logo {
        width: 100px;
        height: 100px;
        
        transition: all 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
      .title{
        font-size: 32px;
        color: #333333;
        line-height: 45px;
        text-align: center;
        margin:15px 0 36px 0 ;
      }
    }
  }

  .copyright {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    mix-blend-mode: normal;
    opacity: 0.4;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;

  }
}
</style>
