

const afterSale = {
    state: {
      afterTypeMap: {
        1: '仅退款',
		    2: '退货退款'
      },
      // 销售订单 售后状态
      afterOrderStateMap: {
        1: '已同意',
        2: '已拒绝',
        3: '待处理'
      },
      // 销售订单 售后状态ARRAY
      afterOrderStateArr: [{
          value: '3',
          name: '待处理'
        },
        {
          value: '1',
          name: '已同意'
        },
        {
          value: '2',
          name: '已拒绝'
        },
      ],
      // 售后结算时  结算方式
      // 账单状态
      settlementTypeMap: {
        1: '余额结算',
        2: '直接打款'
      },
    },
    mutations: {
     
    },
    actions: {
   
    }
}
export default afterSale
