import requestCustom from '@/utils/requestCustom'

// 获取系统通知
export function getSysNoticeList(params) {
  return requestCustom({
    url: "/need/message/list",
    method: "get",
    params
  });
}
// 消息已读
export function changeReadState(id) {
  return requestCustom({
    url: `/need/message/changeRead/${id}`,
    method: "get"
  });
}