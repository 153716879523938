
import _ from 'lodash'
import {getOrderInfo,getOrderByHxId,getGoodsByHxId,getBacklistByHxId,getNearVisibleByHxId,getSaleOrderByHxId,appraiseOrderIMList} from "@/api/custom.js"
import router from '@/router'
import Message from './message'
const Orders = {
    state: {
        orderInfo: {},
        goodsInfo: {},//群聊相关商品信息
        // '交易中', '待审核', '已审核', '交易完成', '交易失败'
        backorderStatusMap:{//回收订单状态
          1:'交易中',
          2:'待审核',
          3:'已审核',
          4:'交易完成',
          5:'交易失败',
        },
        orderStatusMap:{
          3: "待付款",
          4: "交易中",
          1: "交易完成",
          2: "交易失败",
        },
        buyerPayState:{
          1: "未支付",
          2: "已支付",
        },
        paywayList:[
          {
            name: '支付宝',
            id: 2,
            icon: '/static/pay/zfblogo.png'
          },
          {
            name: '微信支付',
            id: 1,
            icon: '/static/pay/wxlogo.png'
          }
        ],
        backlist:[],//单聊 右侧商品列表
        nearVisibleGoods:[],//单聊 右侧 用户最近浏览
        nearVisibleGoodsTotal:0,//单聊 右侧 用户最近浏览 总条数
        saleOrderListCurUser:[],//单聊 右侧 用户订单
        saleOrderListCurUserTotal:0,//单聊 右侧 用户订单 总条数
        jiandingOrderList:[],//单聊 右侧 鉴定订单
        backOrderInfo:{},
        signContractStateMap:{
          '1':'未发起合同',
          '2':'合同签署中',
          '3':'合同已签署',
        },
        backStateMap:{
          '1':'待回收',
          '2':'回收中',
          '3':'已回收',
          '4':'已取消',
        },
        buyerPayState: {
          '1': '未支付',
          '2': '已支付'
        },
        // 通用结算状态
        sellerPayState: {
          '1': {
            type: 'danger',
            label: '未结算'
          },
          '2': {
            type: 'success',
            label: '已结算'
          }
        },
        bstatusMap:{ // 回收列表状态
          0: {
            label: "报价中",
            type: "warning",
          },
          2: {
            label: "已接受",
            type: "success",
          },
          // 2: {
          //   label: "已拒绝",
          //   type: "danger",
          // },
          3: {
            label: "已完成",
            type: "success",
          },
          4: {
            label: "已取消",
            type: "danger",
          },
          5: {
            label: "拒绝报价",
            type: "danger",
          },
          6: {
            label: "已报价",
            type: "success",
          },
        },
        saleOrderStatusMap:{ // 销售订单状态
          1: {
            label: "交易完成",
            type: "success",
          },
          2: {
            label: "交易失败",
            type: "danger",
          },
          3: {
            label: "待付款",
            type: "",
          },
          4: {
            label: "交易中",
            type: "warning",
          }
        },
        jiandingOrderMap:{ // 鉴定订单状态
          1: {
            label: "未支付",
            type: "info",
          },
          2: {
            label: "鉴定中",
            type: "warning",
          },
          3: {
            label: "鉴定中",
            type: "",
          },
          4: {
            label: "鉴定完成",
            type: "success",
          },
          5: {
            label: "已取消",
            type: "danger",
          }
        },
    },
    mutations: {
        SET_ORDER_INFO: (state, payload) => {
            state.orderInfo = payload
        },
        SET_GOODS_INFO: (state, payload) => {
            state.goodsInfo = payload
        },
        SET_BACKLIST: (state, payload) => {
            state.backlist = payload
        },
        SET_nearVisibleGoods: (state, payload) => {
            state.nearVisibleGoods = payload
        },
        SET_nearVisibleGoodsTotal: (state, payload) => {
          state.nearVisibleGoodsTotal = payload
      },
        // saleOrderListCurUser
        SET_saleOrderListCurUser: (state, payload) => {
          state.saleOrderListCurUser = payload
      },
      SET_saleOrderListCurUserTotal: (state, payload) => {
          state.saleOrderListCurUserTotal = payload
      },
      // jiandingOrderList
      SET_jiandingOrderList: (state, payload) => {
          state.jiandingOrderList = payload
      },
    },
    actions: {
      // 更新聊天页
      reloadPage({dispatch, commit },params){
        if(params.flag=='clickConversation'||(router.currentRoute.value.path=='/chat/conversation'&&Message.state.currentChatId== params.conversationKey )){
          if(params.chatType=="groupChat"){
              // 获取群聊相关回收订单
              dispatch('getGoodsInfo',params.conversationKey)
          }else {
            // 判断角色
            const temp = localStorage.getItem('customUserInfo')
            if (temp) {
              const userInfo = JSON.parse(temp)
              if(userInfo&&userInfo.roles){
                const roleArr = Array.from(userInfo.roles,({roleKey})=>roleKey)
                if(roleArr.includes('salesCustomer')){
                   // 获取单聊用户最近浏览商品 getNearVisibleBySingleChat
                  dispatch('getNearVisibleBySingleChat',{hxId:params.conversationKey})
                  // 获取用户的买号订单 getSaleOrdersBySingleChat
                  dispatch('getSaleOrdersBySingleChat',{hxId:params.conversationKey})
                }
                if(roleArr.includes('youxizhishou')){
                     // 获取单聊相关 回收商品
                  dispatch('getGoodsInfoBySingleChat',params.conversationKey)
                }
                if(roleArr.includes('jiandingshi')){
                  // 获取单聊相关 鉴定订单
                  dispatch('getJiandingOrdersBySingleChat',{hxId:params.conversationKey})
                }
              }
            }
          }
          // 清空聊天框本地消息 重新获取
          commit('DELETE_ALL_MESSAGE_BY_ID',params.conversationKey)
          dispatch('getHistoryMessage',{
            cursor: -1,
            id:params.conversationKey,//对方环信id 当前聊天窗口的id
            chatType:params.chatType,
          })

        }
      },
      // 获取群聊相关的商品信息
      getGoodsInfo:async ({ commit },groupId) => {
        try {
            const { data } = await getGoodsByHxId({toGroup:groupId})
            //console.log("%c Line:27 🧀拿到商品数据 data", "color:#e41a6a", data);
            if(data){
              commit('SET_GOODS_INFO', data)
            }else{
              commit('SET_GOODS_INFO', {})
            }
        } catch (error) {
          //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
          commit('SET_GOODS_INFO', {})
        }
      },
      // 获取单聊相关商品信息 传卖家环信id
      getGoodsInfoBySingleChat:async ({ commit },hxId) => {
        try {
          // ,transfer:1
            const { rows } = await getBacklistByHxId({sellerhxusername:hxId})
            //console.log("%c Line:27 🧀拿到商品数据 data", "color:#e41a6a", data);
            if(rows){
              commit('SET_BACKLIST', rows)
            }else{
              commit('SET_BACKLIST', [])
            }
        } catch (error) {
          //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
          commit('SET_BACKLIST', [])
        }
      },
      // 获取单聊 用户最近浏览 传卖家环信id
      getNearVisibleBySingleChat:async ({ commit,state },params) => {
        let pageSize = params.pageSize||3
        let pageNum = params.pageNum||1
        let hxUserId = params.hxId||'1'
        
        try {
          // ,transfer:1
            const res = await getNearVisibleByHxId({hxUserId,pageSize,pageNum})
           
            if(res.rows&&res.rows.length){
              commit('SET_nearVisibleGoods', res.rows)
              
            }else{
              commit('SET_nearVisibleGoods', [])
              commit('SET_nearVisibleGoodsTotal',0)
            }
            commit('SET_nearVisibleGoodsTotal',res.total)
        } catch (error) {
          //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
          commit('SET_nearVisibleGoods', [])
          commit('SET_nearVisibleGoodsTotal',0)
        }
      },
      // 获取单聊 用户的买号订单 传卖家环信id
      getSaleOrdersBySingleChat:async ({ commit,state },params) => {
        let pageSize = params.pageSize||3
        let pageNum = params.pageNum||1
        let hxUserId = params.hxId||'1'
        try {
          // ,transfer:1
            const res = await getSaleOrderByHxId({hxUserId,pageSize,pageNum})
           
            if(res.rows&&res.rows.length){
              commit('SET_saleOrderListCurUser', res.rows)
              
            }else{
              commit('SET_saleOrderListCurUser', [])
              commit('SET_saleOrderListCurUserTotal',0)
            }
            commit('SET_saleOrderListCurUserTotal',res.total)
        } catch (error) {
          //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
          commit('SET_saleOrderListCurUser', [])
          commit('SET_saleOrderListCurUserTotal',0)
        }
      },
      // 获取单聊 用户的鉴定订单 
      getJiandingOrdersBySingleChat:async ({ commit,state },params) => {
        let pageSize = params.pageSize||''
        let pageNum = params.pageNum||''
        let hxusername = params.hxId||'1'
        try {
          // ,transfer:1
            const res = await appraiseOrderIMList({hxusername,pageSize,pageNum})
           
            if(res.rows&&res.rows.length){
              commit('SET_jiandingOrderList', res.rows)
              
            }else{
              commit('SET_jiandingOrderList', [])
              // commit('SET_saleOrderListCurUserTotal',0)
            }
            // commit('SET_saleOrderListCurUserTotal',res.total)
        } catch (error) {
          //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
          commit('SET_jiandingOrderList', [])
          // commit('SET_saleOrderListCurUserTotal',0)
        }
      },
        // // 获取回收订单信息
        // getOrderInfo: async ({ commit },groupId) => {
        //   //console.log("%c Line:24 🥃获取回收订单信息 groupId", "color:#465975", groupId);
        //     try {
        //         const { data } = await getOrderInfo(groupId)
        //         //console.log("%c Line:27 🧀拿到订单数据 data", "color:#e41a6a", data);
        //         if(data){
        //           if(data.img){
        //             let imgs = JSON.parse(data.img)
        //             //console.log("%c Line:31 🍇 imgs", "color:#6ec1c2", imgs);
        //             if(imgs&&imgs.length){
        //               data.titleImg = imgs[0].url
        //             }
        //           }
        //           commit('SET_ORDER_INFO', data)
        //         }else{
        //           commit('SET_ORDER_INFO', {})
        //         }
        //     } catch (error) {
        //       //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
        //       commit('SET_ORDER_INFO', {})
        //     }
        // },
        // // 单聊获取回收订单信息
        // getOrderInfoByPerson: async ({ commit },userId) => {
        //   //console.log("%c Line:24 单聊获取回收订单信息 userId", "color:#465975", userId);
        //     try {
        //         const { data } = await getOrderByHxId(userId)
        //         //console.log("%c Line:27 🧀拿到订单数据 data", "color:#e41a6a", data);
        //         if(data){
        //           if(data.img){
        //             let imgs = JSON.parse(data.img)
        //             //console.log("%c Line:31 🍇 imgs", "color:#6ec1c2", imgs);
        //             if(imgs&&imgs.length){
        //               data.titleImg = imgs[0].url
        //             }
        //           }
        //           commit('SET_ORDER_INFO', data)
        //         }else{
        //           commit('SET_ORDER_INFO', {})
        //         }
                
        //     } catch (error) {
        //       //console.log("%c Line:30 🍎 error", "color:#6ec1c2", error);
        //       commit('SET_ORDER_INFO', {})
        //     }
        // }
    }
}

export default Orders
