 export default {
  // defaultBaseUrl:'//192.168.3.102:19999',
  // h5Host:'http://192.168.3.101',
  // shdHost:'http://192.168.3.101:89',
  // PChost:'http://test.pc.ikbh.top'

  // defaultBaseUrl:'//test.back.ikbh.top',
  // h5Host:'http://test.app.ikbh.top',
  // shdHost:'/erp',
  // PChost:'http://test.pc.ikbh.top'

  h5Host:'https://h5.yududs.com',
  defaultBaseUrl:'//back.yududs.com',
  shdHost:'/erp',
  PChost:'https://yududs.com'
}